import React from 'react';

import SectionBlock from '../components/Block/SectionBlock';

import './careers.scss';

const sections = [
  {
    img: false,
    text: [
      {
        title: 'Join us!',
        description:
          '<p>We are a team of passionate and tech-savvy engineers. Join us and work under innovative projects dedicated to ' +
          'our clients around the world. Let’s see if we are looking for you.</p>'
      },
    ],
  },
  {
    img: '/team/team_table.jpg',
    text: [
      {
        title: 'Remote-first company',
        description:
          '<p>We offer the option to work in an office or remotely for the vast majority of roles.</p> ' +
          '<p>We’ve noticed lots of benefits of working remotely:</p>' +
          '<ul>' +
          '<li>Freedom and flexibility in your daily life</li>' +
          '<li>A trusting relationship with the managers</li>' +
          '<li>Increased productivity</li>' +
          '<li>A more creative mindset</li>' +
          '<li>A chance to set yourself apart as lead on your team</li>' +
          '</ul>' +
          '<p>We use the same process to hire remote workers and on-site workers.</p>',
      },
    ],
  },
  {
    img: '/team/team_rolek.jpg',
    text: [
      {
        title: 'Choose your team',
        description:
          '<ul>' +
          '<li>Backend</li>' +
          '<li>Frontend</li>' +
          '<li>Mobile</li>' +
          '<li>Firmware</li>' +
          '<li>HR/Business</li>' +
          '<li>Marketing</li>' +
          '<li>Project Management</li>' +
          '</ul>' +
          '<p>' +
          `Haven't found your dream position, but want to get in touch? Apply anyway.` +
          '</p>' +
          '',
      },
    ],
  },
  {
    img: '/team/team_values.jpg',
    text: [
      {
        title: 'Our values',
        description: '<p>There are a few things we strongly believe in the way we live and work.</p>',
      },
    ],
  },
  {
    img: false,
    text: [
      {
        title: 'Recruitment process',
        description:
          '<p>We value your time and try to complete the whole recruitment process within 2 weeks.  After each stage,' +
          ' we share immediate feedback about your performance.</p>' +
          '<h4>1. CV Review</h4>' +
          '<p>Our technical recruiter is reviewing your CV and assesses your skills and experience by doing a short ' +
          'phone screen which takes up to 30 minutes.</p>' +
          '<h4>2. Technical and language assessment</h4>' +
          '<p>We ask you to solve the home task or in some cases to send over the repo to be checked by our developers ' +
          'and do a 15 minutes online test in English</p>' +
          '<h4>3. Interview</h4>' +
          '<p>We meet together in our office and talk about your experience and check the maturity to run a project ' +
          'dedicated to a specific client.</p>' +
          '<h4>4. Offer sent</h4>' +
          '<p>After passing the interview stage, you get the job offer letter including all conditions and ' +
          'benefits we want to offer you. As you accept it, we go to formalities.</p>' +
          '<h4>5. Contract and onboarding</h4>' +
          '<p>As you accept our offer, we send you a draft of the contract for review. Once your contract has been ' +
          'negotiated and the start date draws nearly, you will receive an invitation to your onboarding day.</p>' +
          '',
      },
    ],
  },
  {
    img: false,
    text: [
      {
        title: 'Why work at SolveQ?',
        description:
          '<p>We value passionate people who are committed to their job. Responsibility, proactive ' +
          'approach and the ability to find the best possible solutions - that’s what we value highly in our team. ' +
          'We believe that constant self-development and exchange of knowledge and experiences allow our team to ' +
          'stay ahead and deliver the best solutions to our clients. We love sharing our passions with each other and ' +
          'spending time together after work.</p>',
      },
    ],
  },
  {
    img: false,
    text: [
      {
        title: 'What do our employees say about us?',
        description:
          '<p>' +
          '<cite>SolveQ is a socially responsible company that takes seriously the problems of today’s world. We ' +
          'segregate waste, reduce energy and water using. The atmosphere inside is very loose without corporate ' +
          'style. Moreover, we create solutions using a large number of users.</cite>' +
          '</p>' +
          '<p>' +
          '<cite>High culture of working with clients allows me as a programmer to enjoy every line of code. Here you ' +
          'create a harmonious team, your contribution to the development does not disappear. A great advantage of ' +
          'the company is the concern for the environment and human rights.</cite>' +
          '</p>' +
          '<p>' +
          '<cite>Freedom to make choices, being responsible for my work done, support from colleagues, cozy atmosphere. ' +
          'These are the factors I pay attention to the most.</cite>' +
          '</p>',
      },
    ],
  },
  {
    img: false,
    text: [
      {
        title: 'What we offer',
        description:
          '<ul>' +
          '<li>Remote working- you choose if you want to work from the office or remotely</li>' +
          '<li>Paid holiday and sick leave/B2B - in case of B2B we offer 20 days off per year and 5 days for a sick leave paid</li>' +
          '<li>Training budget - we provide 1500 PLN/net per person per year to use on conferences and workshop tickets or courses</li>' +
          '<li>Flexible working hours - you can choose when you can start and end your work</li>' +
          '<li>Internal workshops - we organize internal sessions where you can share your knowledge, experience and ' +
          'skills or listen to others</li>' +
          '<li>Multisport Card - we cover 50% of the Multisport Card</li>' +
          '<li>Regular feedback - we meet regularly to hear your opinion about how you feel working with us and get ' +
          'the information from the team about your work</li>' +
          '<li>Vegan-friendly breakfasts - we love green and once a month we meet together to update each other ' +
          'about progress in projects and eat healthy and vegan breakfasts</li>' +
          '<li>Company retreats - we believe every retreat is unforgettable and integrates everybody that is why ' +
          'we meet and try new activities to play together</li>' +
          '<li>Development path- planning with HR your development path and supporting with gain new skills</li>' +
          '</ul>',
      },
    ],
  },
  {
    img: false,
    text: [
      {
        title: 'Learning and development',
        description:
          '<ul>' +
          '<li>Knowledge sharing session every month conducted by our developers internally</li>' +
          '<li>Lightning Talks - 7-10 minutes presentations about interesting topics</li>' +
          '<li>Opportunity to take part in conferences/ IT events/ meetups</li>' +
          '<li>English speaking environment</li>' +
          '<li>Space to develop your own ideas</li>' +
          '<li>Possibility to improve  your knowledge about specific fields: technical, management, sales processes, ' +
          'HR, marketing, project management</li>' +
          '<li>Possibility to work under innovative and advanced projects using the newest technologies stack and tools</li>' +
          '</ul>',
      },
    ],
  },
];

const CareersPage = () => (
  <>
    <div className="th-hero-page mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">Careers</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock {...sections[0]} noPadding />
      <div className="container">
        <div className="row">
          <div className="col-12">
          <a href="https://solveq.zohorecruit.eu/jobs/Careers">
            <div className="btn btn-warning mb-3">Check open positions!</div>
            </a>
            </div>
        </div>
      </div>
    <SectionBlock {...sections[1]} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock imgFirst noPadding {...sections[2]} />
    {/* <div className="th-article-content th-article-content--bg-color">
      <div className="th-article-main">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-md-9">
              <h3 className="th-article-content__header th-article-content__header--decorated">Check open positions</h3>
            </div>
            <div className="col-md-3">
              <div className="th-service-title-button text-md-right">
                <a href="https://solveq.zohorecruit.eu/jobs/Careers">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {/* <SectionBlock imgFull noPadding {...sections[2]} /> */}
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock {...sections[4]} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock {...sections[5]} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock {...sections[6]} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock {...sections[7]} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock {...sections[8]} />
  </>
);

export default CareersPage;
